import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import About from "../About/About";
import Technologies from "../Technologies/Technologies";
import styles from "./Home.module.css";
import { Box } from "@mui/material";
import ScrollDownIcon from "@mui/icons-material/South";

const Home = () => {
  const [inViewStyle, setInViewStyle] = useState("");
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      setInViewStyle("moveDownFadeOut");
    }
    return () => setInViewStyle("");
  }, [inView]);

  return (
    // TODO: // Change over to end before face, orange to white, text match, pull pink, non all caps titles
    <Box ref={ref} className={styles.home}>
      <Box
        className={inViewStyle}
        component="span"
        sx={{ opacity: 0, position: "absolute", top: "85%", left: "1%" }}
      >
        <ScrollDownIcon fontSize="large" sx={{ color: "#0c77ff" }} />
      </Box>
      <Box className={styles.shapes_container}>
        <Box className={styles.shapes_container__left} >
          <About className={styles.homebox_about} />
        </Box>
        <Box className={styles.shapes_container__right}/>
      </Box>
      <Box className={ styles.designer_image} />
      <Technologies className={styles.homebox_tech} />
    </Box>
  );
};

export default Home;
